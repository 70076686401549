import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/HomePage/MainPage";
import ServicePage from "./components/ServicePage/ServiceMainPage";
import ContactPage from "./components/ContactPage/ContactPage";
import ProjectPage from "./components/ProjectPage/ProjectMainPage";
import WebsitePage from "./components/WebsitePage/WebsiteMainPage";
import ErrorPage from "./components/ErrorPage/ErrorMainPage";
import PrivacyPage from "./components/Privacy-Policy/Privacy"
import Terms from "./components/Terms-Conditions/Terms"
import AboutUs from "./components/AboutUs/About";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*Mainpage */}
        <Route path="/" element={<MainPage />} />
        {/*Service page */}
        <Route path="/services" exact element={<ServicePage />} />
        {/*Contact page */}
        <Route path="/contact" exact element={<ContactPage />} />
        {/*Projects page*/}
        <Route path="/projects" exact element={<ProjectPage />} />
        {/*Website page */}
        <Route path="/website" exact element={<WebsitePage />} />
        {/*Error page */}
        <Route path="*" exact element={<ErrorPage />} />
        {/*Privacy-Policy*/}
        <Route path="/privacy" exact element={< PrivacyPage />} />
        {/*Terms-Condition*/}
        <Route path="/terms" exact element={< Terms />} />
        {/*About-us*/}
        <Route path="/about" exact element={< AboutUs />} />
      </Routes>
    </BrowserRouter>
  );
}
