import React from "react";
import { Link } from "react-router-dom";
import Contact from "../HomePage/Contact";
export default function Contact2() {
  return (
    <>
      <div className="hero2 flex-col pt-8 lg:pt-8 justify-center items-center">
        <div className="-mb-5 ml-5 pt-10 lg:pt-5 text-white text-medium text-[1.3rem]">
          <span>
            <Link to="/">
              <span className="hover:text-[#74e4c0]">Home &#8594; </span>
            </Link>{" "}
          </span>
          <span className="text-[#74e4c0]">Contact Us</span>
        </div>
        <Contact />
      </div>
    </>
  );
}
