import React from 'react';
import { Link } from 'react-router-dom';
import instagram from '../../Codewiks/Group 193.png';
import link from '../../Codewiks/Group 194.png';
import twitter from '../../Codewiks/twitter.png';
import facebook from '../../Codewiks/Facebook.png';
import location from '../../Codewiks/location.png';
import call from '../../Codewiks/call.png';


export default function Footer() {
  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
    return (
      <>
        <div className="w-full bg-black px-12 py-12 ">
          <div className=" flex flex-col md:flex text-white">
            <div className=" flex-col sm:grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-20 lg:gap-12 ">
              <div className="leading-5 flex-col mb-10 lg:mb-0">
                <div className=" text-[2.5rem] text-extrabold">
                  <Link to="/">
                    <div onClick={handleClick}>
                      <span className="text-[#74e4c0]">C</span>odekwicks
                      <span className="text-[#74e4c0]">.</span>
                    </div>
                  </Link>
                </div>
                <div className="text-[1.4rem] lg:text-[1.2rem] leading-8 lg:leading-6 text-white mt-5">
                  <p>
                    Codekwiks is a full service technology consulting, design
                    and development firm with office in Los Angeles.{" "}
                  </p>
                </div>
                <div className="text-[1.3rem]  lg:text-[1.1rem] mt-10 mb-8">
                  team@codekwiks.com
                </div>
                <div className="flex-col md:flex-row lg:flex gap-3">
                  <div className="mb-5 hidden">
                    <img src={instagram} alt="" />
                  </div>
                  <div className="mb-5 hidden">
                    <img src={link} alt="" />
                  </div>
                  <div className="mb-5 hidden">
                    <img className="w-9" src={twitter} alt="" />
                  </div>
                  <div className="mb-5 hidden">
                    <img className="w-9" src={facebook} alt="" />
                  </div>
                </div>
              </div>
              <div className="leading-8 md:pl-16 flex-col cursor-pointer mb-10 lg:mb-0">
                <div className="cursor-auto mb-3 text-[1.5rem]  lg:text-[1.4rem]">
                  <strong>
                    <b>Highlights</b>
                  </strong>
                </div>
                <Link to="/">
                  <div
                    onClick={handleClick}
                    className="text-[1.3rem]  lg:text-[1.1rem] hover:text-[#74e4c0]"
                  >
                    Home
                  </div>
                </Link>
                <Link to="/about">
                  <div
                    onClick={handleClick}
                    className="text-[1.3rem]  lg:text-[1.1rem] hover:text-[#74e4c0] "
                  >
                    About Us
                  </div>
                </Link>
                <Link to="/services">
                  <div
                    onClick={handleClick}
                    className="text-[1.3rem]  lg:text-[1.1rem] hover:text-[#74e4c0]"
                  >
                    Services
                  </div>
                </Link>
                <Link to="/contact">
                  <div
                    onClick={handleClick}
                    className="text-[1.3rem]  lg:text-[1.1rem] hover:text-[#74e4c0]"
                  >
                    Contact Us
                  </div>
                </Link>
                <a  href="https://calendly.com/parhampahlevani/30min">
                <div style={{ fontWeight: 'bold', textDecoration: 'none', cursor: 'pointer',transition: 'color 0.9s', }}>
                Book Appointment
                </div>
                </a>
                <Link to="/projects">
                  <div
                    onClick={handleClick}
                    className="text-[1.3rem]  lg:text-[1.1rem] hover:text-[#74e4c0] mb-10"
                  >
                    Projects
                  </div>
                </Link>
                
              </div>
              <div className="leading-8 flex-col cursor-pointer mb-10 lg:mb-0">
                <div className="cursor-auto text-[1.5rem]  lg:text-[1.4rem] mb-3">
                  <strong>
                    <b>Services</b>
                  </strong>
                </div>
                <a href="/services#1">
                  {" "}
                  <div
                    onClick={handleClick}
                    className="hover:text-[#74e4c0] text-[1.3rem]  lg:text-[1.1rem]"
                  >
                    Web Development
                  </div>
                </a>
                <a href="/services#2">
                  <div
                    onClick={handleClick}
                    className="hover:text-[#74e4c0] text-[1.3rem]  lg:text-[1.1rem]"
                  >
                    Mobile Development
                  </div>
                </a>
                <a href="/services#3">
                  {" "}
                  <div
                    onClick={handleClick}
                    className="hover:text-[#74e4c0] mb-10 text-[1.3rem]  lg:text-[1.1rem]"
                  >
                    UI/UX Designing
                  </div>
                </a>
              </div>
              <div className="leading-8 flex-col mb-10 lg:mb-0">
                <Link to="/contact">
                  {" "}
                  <div
                    className="cursor-pointer text-[1.5rem]  lg:text-[1.4rem]"
                    onClick={handleClick}
                  >
                    <strong>
                      <b>Contact Us</b>
                    </strong>
                  </div>
                </Link>
                <div className="p-3 w-[3rem] mt-3">
                  <img src={location} alt="" />
                </div>
                <div className="font-semibold text-white text-[1.3rem]  lg:text-[1.1rem]">
                  <p className="leading-5">
                    606 Levering Ave, Los Angeles,
                    <br /> California, 90024
                  </p>
                </div>
                <div className="w-[3rem] p-3 mb-1 mt-2">
                  <img src={call} alt="" />
                </div>
                <div className=" mb-10 font-semibold text-[1.3rem]  lg:text-[1.1rem]">
                  <p className="leading-5"> +1 818 458 0086</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'space-between'}} className="text-center text-gray-300">
            <div> <p>@ 2023, By Codekwiks</p></div>
            <div style={{display:'flex',gap:'20px'}}>
            <Link to="/terms">  <div>Terms and Conditions</div></Link> 
            <Link to="/privacy">    <div>Privacy Policy</div></Link> 

            </div>
            
           
          </div>
        </div>
      </>
    );
}







	







