import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";

export default function TshirtWebsite({ projectDetail }) {

  useEffect(() => {
    let hamburger = document.querySelector(".hambur");
    let items = document.querySelector(".items");
    let cancel = document.querySelector(".cancel");
    let pages = document.querySelectorAll(".item");
    pages.forEach((page) => {
      page.addEventListener("click", () => {
        document.querySelector(".item1").classList.remove("item1");
        page.classList.add("item1");
        items.classList.add("-right-60");
        items.classList.remove("right-0");
        hamburger.classList.remove("hidden");
      });
    });

    hamburger.addEventListener("click", () => {
      hamburger.classList.add("hidden");
      items.classList.add("flex-col");
      items.classList.add("ml-20");
      items.classList.remove("-right-60");
      items.classList.add("right-0");
      cancel.classList.remove("hidden");
      items.classList.add("pb-10");
      items.classList.add("gap-10");
      cancel.addEventListener("click", () => {
        hamburger.classList.remove("hidden");
        cancel.classList.add("hidden");
        items.classList.add("-right-60");
        items.classList.remove("right-0");
        items.classList.remove("flex-col");
      });
    });
  });
  return (
    <div>
      <div className=" nav flex relative lg:static pt-6 pb-2  px-8 lg:px-20 items-center justify-between  lg:justify-left lg:gap-40 bg-black text-white z-100 w-full cursor-pointer">
        {/* left */}
        <div className=" text-[2rem] md:text-[2.1rem] lg:text-[2.4rem] text-extrabold hover:border-b-2 hover:border-[#74e4c0] p-1">
          <Link to="/">
            <span className="text-[#74e4c0]">C</span>odekwicks
            <span className="text-[#74e4c0]">.</span>
          </Link>
        </div>
        <div className="flex ">
          <div className="hambur flex lg:hidden -mt-4">
            <GiHamburgerMenu size={22} />
          </div>
          <div className="items fixed z-1000 -right-60 top-0 h-[54rem] md:h-[80rem] lg:h-10 lg:static duration-500 lg:duration-75 rounded lg:rounded-none pl-5 sm:pl-5 md:pl-10 pr-10 md:pr-3 pt-4 lg:mt-0 lg:pt-2  bg-slate-600 lg:bg-black lg:flex lg:gap-12 xl:gap-16 justify-between">
            <div className="hidden cancel ml-24  hover:text-[#74e4c0] mb-5">
              <MdOutlineCancel size={22} />
            </div>
            <Link to="/">
              <div className=" item text-slate-100 hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold font-normal w-[3rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2">
                Home
              </div>
            </Link>
            <Link to="/services">
              <div className=" item   text-slate-100 hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold font-normal w-[5rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2">
                Services
              </div>
            </Link>
            <Link to="/contact">
              <div className=" item  text-slate-100 font-normal hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold  w-[7rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2">
                Contact Us
              </div>
            </Link>
            <Link to="/projects">
              <div className=" item  item1 text-slate-100  font-normal hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold w-[5rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2">
                Projects
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="midbar1 text-white w-full pt-32 pb-40 items-center justify-center lg:h-[40rem] align-middle text-center">
        <div className="text-[3rem] text-center lg:text-[5rem] font-bold px-5 mb-6 lg:mb-10">
          {projectDetail?.title}<span className="text-[#74e4c0] font-thin">.</span>{" "}
        </div>
        <div className="text-[1.5rem] md:text-[2rem] font-medium text-white px-10 lg:px-5 leading-8 opacity-80 text-center">
          {/* We specialize in building beautiful,professional and easy-to-use
          websites.
          <br /> It includes from small business websites to large numb
          e-commerce websites.
          <br /> We build engaging,responsive,secure and intutive website. */}
          {projectDetail?.description}
        </div>
      </div>
    </div>
  );
}
