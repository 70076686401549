/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../../App.css";
import { Link, useNavigate } from "react-router-dom";
import border from "../../Codewiks/Group 371.png";
import { baseURL } from "../../constant";
import { useQuery } from "react-query";
const url = `${baseURL}/projects`;

export default function Project2() {

  const navigate = useNavigate()
  const redirectTo = (endpoint) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return navigate(endpoint)
  }

  const { isLoading, error, data } = useQuery('projects', async () => {
    const response = await fetch(url)
    const result = await response.json();
    return result
  })
  console.log(data)

  let [projects, setProjects] = useState(data);
  let [projectSlice, setProjectSlice] = useState(8);
  const [Disable, setDisable] = useState(false);


  useEffect(() => {
    setProjects(data)
  }, [isLoading])

  const [loading, setLoading] = useState(false);



  const filterproject = async (btn) => {
    setLoading(true);
    setProjectSlice(4)
    setDisable(false)
    const projectss = data;
    const updatedItems = projectss.filter((curElm) => curElm.btn === btn);
    setProjects(updatedItems);

    if (updatedItems !== []) {
      setLoading(false)
    }
  };

  const handleClick = () => {
    setProjectSlice(projectSlice + 8)
  };
  useEffect(() => {
    if (projects?.length === 0 || projectSlice >= projects?.length) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [projectSlice, projects])

  let pages = document.querySelectorAll(".items");
  pages.forEach((page) => {
    page.addEventListener("click", () => {
      document.querySelector(".item2").classList.remove("item2");
      page.classList.add("item2");
    });
  });
  return (
    <>
      <div className="hero p-10  lg:px-0 lg:py-8 border-b border-gray-500  w-full">
        <div className="grid grid-cols-2 lg:px-10  md:flex md:flex-row gap-10 cursor-pointer">
          <div
            onClick={() => {
              setProjects(data)
              setDisable(false)
              return setProjectSlice(2)
            }}
            className=" item2 items text-slate-100 font-normal  text-[1.5rem] lg:text-[1.3rem] lg:mb-2 hover:border-b-2 hover:border-[#74e4c0] hover:text-[#74e4c0]"
          >
            All Projects
          </div>
          <div
            onClick={() => {
              filterproject("Website");
            }}
            className="items text-slate-100 font-normal text-[1.5rem] lg:text-[1.3rem] lg:mb-2 cursor-pointer hover:border-b-2 hover:border-[#74e4c0] hover:text-[#74e4c0]"
          >
            Website
          </div>
          <div
            onClick={() => {
              filterproject("Case Study");
            }}
            className=" items  text-slate-100 font-normal  text-[1.5rem] lg:text-[1.3rem] lg:mb-2 cursor-pointer hover:border-b-2 hover:border-[#74e4c0] hover:text-[#74e4c0]"
          >
            Case Study
          </div>
          <div
            onClick={() => {
              filterproject("Mobile Apps");
            }}
            className="  items text-slate-100  font-normal  text-[1.5rem] lg:text-[1.3rem] lg:mb-2 cursor-pointer hover:border-b-2 hover:border-[#74e4c0] hover:text-[#74e4c0]"
          >
            Mobile Apps
          </div>
        </div>
        <div className="flex flex-col md:grid p-10 md:grid-cols-2 lg:pt-2 md:gap-20 lg:gap-10 items-center  justify-center  w-full">
          {isLoading || loading ? (
            <>
              <h3 className=" text-[2rem] text-center flex items-center justify-center text-[#74e4c0]">
                Loading...
              </h3>
            </>
          ) : (
            <>
              {projects?.slice(0, projectSlice).map((project) => {
                const { _id, img, title, description, btn, images } = project;
                return (
                  <div key={_id} className="projects">
                    <div className="flex-col">
                      <div className="bg-transparent lg:p-[2rem] w-auto h-auto mb-12 md:mb-0 flex-col">
                        <div
                          className="flex items-center justify-center md:justify-start"

                        >
                          {" "}
                          <button onClick={() => redirectTo(`/website?id=${_id}`)} className="text-[#74dcbb] bg-gray-700 text-center rounded-sm w-[12rem] lg:w-[10rem] font-semibold mb-8 lg:mb-4  lg:mt-12 py-2 text-[1.1rem]">
                            {btn}
                          </button>
                        </div>
                        <div className="relative z-50 lg:pb-8">
                          <div className="">
                            <img
                              className="lg:h-[26rem] h-auto object-cover lg:mt-4  md:mb-12 lg:mb-0 md:ml-0 rounded-lg"
                              src={img}
                              alt=""
                            />
                          </div>
                          <div
                            className=" absolute w-auto bottom-2 -right-4"
                            style={{ zIndex: -1 }}
                          >
                            <img
                              className="pointer-events-none hidden lg:flex"
                              src={border}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" text-white -mt-12  text-left lg:ml-8 p-2">
                      <div className="text-[2rem] sm:text-[2rem] lg:text-[3rem] font-semibold mb-1">
                        {title.slice(0, 54) + ""}
                      </div>
                      <div className="text-[1.15rem] lg:[1.3rem] text-left w-auto text-slate-300 font-normal lg:text-left mb-4">
                        {description.slice(0, 200) + '...'}
                      </div>
                      <div className="grid grid-cols-2 md:grid-cols-3 lg:my-2  lg:grid-cols-5 justify-center items-center md:w-[20rem] lg:w-[28rem]">
                        {images.map((image) => {
                          const { id, img, title } = image;
                          return (
                            <div
                              key={id}
                              className="flex flex-col mb-2 gap-2 justify-center items-center"
                            >
                              <div className="w-[3rem] mb-1">
                                <img className="" src={img} alt="" />
                              </div>
                              <div className="text-center">{title}</div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-col lg:flex-row gap-2 mb-6 lg:gap-5 justify-center lg:justify-start items-center">
                        <button
                          onClick={() => redirectTo(`/website?id=${_id}`)}
                          className="bg-transparent text-center border-2 hover:border-2 hover:text-blue-500 hover:border-blue-500 w-[12rem] py-2 px-5 font-semibold md:m-5 text-[1.2rem]"
                        >
                          Visit Site &#8594;
                        </button>
                        <button
                          onClick={() => redirectTo(`/website?id=${_id}`)}
                          className="bg-[#74e4c0] hover:bg-[#8332ca] hover:text-white text-black text-center rounded-sm w-[12rem] font-semibold py-2 px-5 text-[1.2rem]"
                        >
                          Know More &#8594;
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="items-center justify-center flex">
          {" "}
          <Link to="/projects">
            <button
              onClick={handleClick}
              className={Disable ? "hidden" : "bg-transparent text-center mb-20 text-white border-2 hover:border-2 hover:text-blue-500 hover:border-blue-500 w-[12rem] py-2 px-5 font-semibold text-[1.2rem]"}
              disabled={Disable}
            >
              Load More &#8594;
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
