import React, { useEffect, useState } from 'react';
import { baseURL } from "../../constant";

export default function MainData() {
  const url = `${baseURL}/data`;
  let [data, setData] = useState([]);
  const filterdata = async (btn) => {
    const response = await fetch(url);
    const users = await response.json();
    setData(users);
  };
  useEffect(() => {
    filterdata();
  }, []);
  //   let data = [{ data1: 100, data2: 70, data3: 6, data4: 25 }];
  return (
    <>{
      data.map((data) => {
        let { data1, data2, data3, data4 } = data;
        return (
          <>
            <div className="flex justify-center items-center flex-col px-24 md:px-10 lg:px-10 gap-3">
              <div className="text-[#74e4c0] text-[4rem] font-bold md:text-[3rem]">
                {data1}+
              </div>
              <div className="text-white -mt-2 ml-2 mb-10 text-[2rem] font-medium md:text-[1.5rem]">
                Projects
              </div>
            </div>
            <div className="flex  justify-center items-center flex-col px-24 md:px-10 lg:px-20 gap-3">
              <div className="text-[#74e4c0] text-[4rem] font-bold md:text-[3rem]">
                {data2}+
              </div>
              <div className="text-white -mt-2 ml-2 mb-10 text-[2rem] font-medium md:text-[1.5rem]">
                Clients
              </div>
            </div>
            <div className="flex  justify-center items-center flex-col px-24 md:px-10 lg:px-20 gap-3">
              <div className="text-[#74e4c0] text-[4rem] font-bold md:text-[3rem]">
                {data3}+years
              </div>
              <div className="text-white -mt-2 ml-2 mb-10 text-[2rem] font-medium md:text-[1.5rem]">
                Experience
              </div>
            </div>
            <div className="flex  justify-center items-center flex-col px-24 md:px-10 lg:px-20 gap-3">
              <div className="text-[#74e4c0] text-[4rem] font-bold md:text-[3rem]">
                {data4}+
              </div>
              <div className="text-white -mt-2 ml-2 pb-10  text-[2rem] font-medium md:text-[1.5rem]">
                Developers
              </div>
            </div>
          </>
        )
      })
    }</>
  );
}

