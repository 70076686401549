import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu, GiPlayButton } from 'react-icons/gi';
import { MdOutlineCancel } from 'react-icons/md';
import '../../App.css';

export default function Midbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    let hamburger = document.querySelector('.hambur');
    let items = document.querySelector('.items');
    let pages = document.querySelectorAll('.item');

    pages.forEach((page) => {
      page.addEventListener('click', () => {
        document.querySelector('.item1').classList.remove('item1');
        page.classList.add('item1');
        items.classList.add('-right-60');
        items.classList.remove('right-0');
        hamburger.classList.remove('hidden');
        closeSidebar();
      });
    });

    hamburger.addEventListener('click', () => {
      setIsSidebarOpen(true);
      closeSidebar();
    });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // const closeSidebar = () => {
  //   setIsSidebarOpen(false);
  // };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isSidebarOpen]);

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    let items = document.querySelector('.items');
    let hamburger = document.querySelector('.hambur');
    items.classList.add('-right-60');
    items.classList.remove('right-0');
    items.classList.remove('flex-col');
    hamburger.classList.remove('hidden');
    document.body.style.overflow = 'auto';
  };

  return (
    <>
     <div className="flex flex-col items-center midbar">
        <div className="nav flex relative lg:static pt-6 pb-2 px-8 lg:px-20 items-center justify-between overflow-x-hidden lg:justify-left lg:gap-40 bg-black text-white z-100 w-full cursor-pointer">
          <div className="text-[2rem] md:text-[2.1rem] lg:text-[2.4rem] text-extrabold hover:border-b-2 hover:border-[#74e4c0] p-1">
            <Link to="/">
              <span className="text-[#74e4c0]">C</span>odekwicks
              <span className="text-[#74e4c0]">.</span>
            </Link>
          </div>
          <div className="flex">
          <div className="hambur flex lg:hidden -mt-4" onClick={toggleSidebar}>
              <GiHamburgerMenu size={22} />
            </div>
            <div className={`items fixed z-10 ${isSidebarOpen ? 'right-0' : '-right-60'} top-0 h-[54rem] md:h-[80rem] lg:h-10 lg:static duration-500 lg:duration-75 rounded lg:rounded-none pl-14 sm:pl-5 md:pl-10 pr-10 md:pr-3 pt-4 lg:mt-0 lg:pt-2  bg-black lg:bg-black lg:flex lg:gap-12 xl:gap-16 justify-between`} style={{ width: isSidebarOpen ? '420px' : '' }}>
          <div className={`cancel ml-44 hover:text-[#74e4c0] mb-5 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={() => closeSidebar()}>
                <MdOutlineCancel size={22} />
              </div>
              <Link to="/">
                <div className={`item1 item text-slate-100 hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold font-normal w-[3rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2`}>
                  Home
                </div>
              </Link>
              <Link to="/services">
                <div className={`item text-slate-100 hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold font-normal w-[5rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2`}>
                  Services
                </div>
              </Link>
              <Link to="/contact">
                <div className={`item text-slate-100 hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold font-normal  w-[7rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2`}>
                  Contact Us
                </div>
              </Link>
              <Link to="/projects">
                <div className={`item text-slate-100 hover:text-[#74e4c0]  hover:border-b-2 hover:border-[#74e4c0] hover:font-semibold  font-normal w-[5rem] lg:w-auto  text-[1.5rem] lg:text-[1.1rem] mb-5 lg:mb-2`}>
                  Projects
                </div>
              </Link>
              <div style={{ display: "inline-block", border: "1px solid white", borderRadius: "8px", overflow: "hidden" }}>
              <a  href="https://calendly.com/parhampahlevani/30min"> <button style={{ padding: "5px", backgroundColor: "transparent", color: "#74e4c0", cursor: "pointer" }}>
      Book Appointment
   </button>
   </a>
</div>

            </div>
          </div>
        </div>
        <div className={`midbar  bg-black text-white w-full items-center pb-20 pt-20 lg:pb-40 lg:h-[40rem] justify-center text-center`}>
          <div className={`text-[3rem] text-center lg:text-[5rem] font-bold px-5 mb-6 lg:mt-10 lg:mb-10`}>
            We design <span className={`text-[#74e4c0] font-thin`}>|</span>{' '}
            develop <span className={`text-[#74e4c0] font-thin`}>|</span>{' '}
            deliver{' '}
          </div>
          <div className={`text-[1.5rem] md:text-[2rem] font-medium text-white opacity-80 px-5 -z-1 leading-9 text-center mb-8`}>
            Converting your ideas into products with engaging
            <br /> designs and scalable technology.
          </div>
          <div className={`flex-col mt-16  md:flex-row gap-28 justify-center items-center`}>
            <Link to="/contact">
              <button className={`bg-transparent hover:border-2 hover:text-blue-500 hover:border-blue-500 text-center border-2 w-[15rem] py-2 md:px-5 font-semibold md:m-5 text-[1.2rem]`}>
                Contact Us &#8594;
              </button>
            </Link>
            <Link to="/projects">
              <button className={`bg-[#74e4c0] hover:bg-[#8332ca] hover:text-white text-black text-center rounded-sm w-[15rem] m-5 font-semibold py-2 px-5 text-[1.2rem]`}>
                Our Projects &#8594;
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
