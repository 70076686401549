import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";




export default function Loading() {
  return (
    <div className="">
      <div className="flex p-10 items-center justify-center w-full h-[100vh] bg-black">
        <div className="flex flex-col items-center text-center justify-center">
          <div className="text-[#74e4c0] font-semibold text-center text-[2rem]">
            Loading...
          </div>
          <div className="">
            <BeatLoader
              loading={true}
              color={"#74e4c0"}
              size={20}
              aria-label="Loading CodeKwiks..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
