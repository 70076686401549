import React, { useEffect } from 'react';
import './About.css';
import { useSpring, animated } from 'react-spring';

// AboutUs component
const AboutUs = () => {
    const animationProps = useSpring({
        opacity: 1,
        transform: 'translateY(0)',
        from: { opacity: 0, transform: 'translateY(-50px)' },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <animated.div style={animationProps} className="bg-black text-white min-h-screen">
            <div className="container mx-auto max-w-screen-2xl p-8">
                <h1 className="text-4xl font-bold mb-8 text-center">About CodeKwiks</h1>

                {/* Company Overview */}
                <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Company Overview</h2>
                    <p>
                        CodeKwiks is a trailblazing technology company dedicated to transforming ideas into innovative digital solutions.
                        Established in [Year], we have been on a mission to empower businesses across the globe through our expertise in
                        web development, mobile app development, cloud services, and database management.
                    </p>
                    <p>
                        With a proven track record of delivering cutting-edge solutions, CodeKwiks stands as a reliable partner for businesses
                        of all sizes, helping them navigate the digital landscape with confidence and achieve sustainable growth.
                    </p>
                </section>

                {/* Our Mission */}
                <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Our Mission</h2>
                    <p>
                        At CodeKwiks, our mission is to revolutionize the tech industry by providing tailored and innovative technology
                        solutions that go beyond expectations. We are committed to being a catalyst for our clients' success, driving
                        efficiency, productivity, and excellence in every project we undertake.
                    </p>
                </section>

                {/* Core Values */}
                <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Core Values</h2>
                    <ul className="list-disc pl-6">
                        <li><strong>Customer-Centric:</strong> Putting our clients' needs first and ensuring their success.</li>
                        <li><strong>Innovation:</strong> Embracing creativity and staying ahead of technological advancements.</li>
                        <li><strong>Integrity:</strong> Upholding the highest ethical standards in all our interactions.</li>
                        <li><strong>Team Collaboration:</strong> Fostering a collaborative and inclusive work environment.</li>
                        <li><strong>Excellence:</strong> Striving for excellence in every project and service we deliver.</li>
                    </ul>
                </section>

                {/* Our Team */}
                <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Meet Our Team</h2>
                    <div className="flex flex-wrap" style={{display:"flex",justifyContent:'space-between'}}>
                        {/* Team Member 1 */}
                        <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                            <div className="bg-black text-white p-4 rounded-lg shadow-lg">
                                {/* <img src="team-member-1.jpg" alt="Team Member 1" className="w-full mb-4 rounded-md" /> */}
                                <h3 className="text-lg font-bold">John Doe</h3>
                                <p className="text-gray-900">Co-Founder & CEO</p>
                                <p>
                                    John is a seasoned entrepreneur with a passion for technology. He co-founded CodeKwiks
                                    with the vision of creating a company that pushes the boundaries of innovation.
                                </p>
                            </div>
                        </div>

                        {/* Team Member 2 */}
                        <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                            <div className="bg-black text-white p-4 rounded-lg shadow-lg">
                                {/* <img src="team-member-2.jpg" alt="Team Member 2" className="w-full mb-4 rounded-md" /> */}
                                <h3 className="text-lg font-bold">Jane Smith</h3>
                                <p className="text-gray-900">Chief Technology Officer</p>
                                <p>
                                    Jane is a tech enthusiast with a wealth of experience in software development.
                                    As the CTO of CodeKwiks, she leads the technical team in delivering top-notch solutions as much she can.
                                </p>
                            </div>
                        </div>

                        {/* Add more team members as needed */}
                    </div>
                </section>

                {/* Additional Section with More Content */}
                <section className="mb-12">
                    <h2 className="text-2xl font-bold mb-4">Innovative Solutions for Every Business</h2>
                    <p>
                        CodeKwiks is your strategic partner for transforming challenges into opportunities. Whether you're a startup
                        looking to make your mark or an established enterprise seeking digital transformation, our team is ready to
                        craft solutions that align with your goals.
                    </p>
                    <p>
                        Explore our comprehensive range of services, including custom software development, mobile app design,
                        cloud architecture, and database management. We leverage the latest technologies to ensure your business stays
                        ahead in the competitive digital landscape.
                    </p>
                </section>

                {/* Contact Us */}
                <section>
                    <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                    <p>
                        Have questions or want to discuss your project with us? Feel free to reach out using the form below in our contact section,
                        and our dedicated team will get back to you promptly.
                    </p>
                    {/* Add your contact form component here */}
                </section>
            </div>
            </animated.div>
    );
}

export default AboutUs;
