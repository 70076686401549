/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import microsoft from '../../Codewiks/MS.svg';
import lti from '../../Codewiks/LTI.svg';
import alt from '../../Codewiks/AT.svg';
import golf from '../../Codewiks/SG.svg';
import supreme from '../../Codewiks/SS.svg';
import { baseURL } from "../../constant";
const url = `${baseURL}/clients`

export default function Clients() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true)
  const getImages = async (btn) => {
    setLoading(true)
    const response = await fetch(url);
    const users = await response.json();
    setImages(users);
    if (images !== []) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getImages();

  }, []);
  console.log(images)
  return (
    <>
      <div className="hero1 bg-black flex-col w-full p-5 md:p-20 lg:py-20 lg:px-40 border-b border-gray-500 justify-center items-center">
        <div className="flex items-center justify-center pt-5 pb-2">
          {" "}
          <button className=" cursor-default text-[#74dcbb] bg-gray-700 text-center rounded-sm font-semibold py-2 px-5 text-[1.1rem]">
            Top Clients
          </button>
        </div>
        <div className="text-white font-medium pb-10 text-center text-[1.8rem] md:text-[2.5rem] lg:text-[3rem]">
          <h1>
            We have worked for<span className="text-[#74e4c0]"> !</span>
          </h1>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2  pb-10 lg:pt-10 lg:mb-10  lg:grid-cols-3 items-center justify-center">{
          loading ?
            <>
              <h3 className="text-white text-[2rem] text-center flex items-center justify-center">Loading...</h3>
            </>
            :
            <>
              {images.map((image) => {
                let { id, img } = image;
                return (
                  <>
                    <div key={id} className="  border-slate-100 flex justify-center items-center py-10">
                      <img className="" src={img} alt="" />
                    </div>
                  </>
                );
              })}
            </>
        }
        </div>
      </div>
    </>
  );
}