/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import border from "../../Codewiks/Group 24.png";
import codekwiks from "../../codekwiks.json";
import xd from "../../Codewiks/adobe-adobe-xd.svg";
import ai from "../../Codewiks/adobe-illustrator.svg";
import lr from "../../Codewiks/adobe-indesign.svg";
import ps from "../../Codewiks/adobe-photoshop.svg";
import web from "../../Codewiks/Group 262.svg";
import mobile from "../../Codewiks/code.svg";
import ux from "../../Codewiks/Group 259.svg";
import react from "../../Codewiks/React.svg";
import figma from "../../Codewiks/figma.svg";
import wordpress from "../../Codewiks/Woedpress.svg";
import html from "../../Codewiks/HTML CSS.svg";
import shopify from "../../Codewiks/Shopify.svg";
import mong from "../../Codewiks/Mongo.svg";
import php from "../../Codewiks/PHP.svg";
import laravel from "../../Codewiks/Larvel.svg";
import code from "../../Codewiks/Cor.svg";
import ios from "../../Codewiks/Group 428.svg";
import android from "../../Codewiks/Group 429.svg";
import { baseURL } from "../../constant";
const url = `${baseURL}/servicepage`;

export default function Services2() {
  // const services = codekwiks.ServicePage;
  const [services, setServices] = useState([]);
  const getServices = async () => {
    const response = await fetch(url);
    const users = await response.json();
    const service = users;
    setServices(service);
    //console.log(users);
  };
  useEffect(() => {
    getServices();
  }, []);
  return (
    <>
      <div className="hero2 border-b border-gray-500 flex flex-col p-5 lg:pt-16  w-full justify-center items-center">
        {services.map((service) => {
          const { id, img, title, description, btn, images } = service;
          return (
            <div
              key={id}
              className="my-10 flex flex-col md:flex-row md:gap-10 lg:mb-20 lg:gap-40 items-center justify-center w-full"
            >
              <div className="flex flex-col relative justify-center items-center">
                <div className="container4  z-10 h-[30rem] flex lg:w-[24rem] justify-center items-center p-[2rem]">
                  <div className="">
                    {" "}
                    <img className="w-[15rem] lg:w-[18rem]" src={img} alt="" />
                  </div>
                </div>
                <div className=" absolute -bottom-6 -right-8 -z-1">
                  <img
                    className=" pointer-events-none w-[21rem] h-[22rem] hidden lg:flex"
                    src={border}
                    alt=""
                  />
                </div>
              </div>
              <div className=" text-white flex-col justify-center items-center text-left p-5">
                <div>
                  <button className="text-[#74dcbb] bg-gray-700 text-center rounded-sm w-[10rem] font-semibold my-4 py-2 text-[1.1rem]">
                    {btn}
                  </button>
                </div>
                <div className="text-[1.3rem] sm:text-[1.9rem] lg:text-[2.5rem] font-semibold mb-5">
                  {title}
                </div>
                <div className=" text-slate-400 font-normal text-left mb-4 w-[17rem]  lg:w-[26rem]">
                  {description}
                </div>
                <div className="grid grid-cols-3 lg:my-2  lg:grid-cols-5 justify-center items-center lg:w-[26rem]">
                  {images.map((image) => {
                    const { id, img, title } = image;
                    return (
                      <div
                        key={id}
                        className=" flex flex-col justify-center items-center text-left mb-2 gap-2 "
                      >
                        <div className="">
                          <img className="w-[3rem]" src={img} alt="" />
                        </div>
                        <div className="text-center">{title}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col mb-2 md:flex-row justify-left items-center">
                  <Link to="/projects">
                    <button className="bg-transparent  hover:border-2 hover:border-blue-500 hover:text-blue-500 text-center border-2 w-[15rem] py-2 px-5 font-semibold text-[1.2rem]">
                      Check Projects &#8594;
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
