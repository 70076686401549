import React, { useState, useEffect } from "react";
import Loading from "../LoaderPage/Loading";
import Header from "./ServiceHeader";
import ServiceMidbar from "./ServiceMidbar";
import Clients from "../HomePage/Clients";
import Contact from "../HomePage/Contact";
import Footer from "../HomePage/Footer";

export default function MainHeader() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
    return (
      <>
        <Header />
        <div>{loading ? <Loading /> : <ServiceMidbar />}</div>
        <div>{loading ? <Loading /> : <Clients />}</div>
        <Contact />
        <Footer />
      </>
    );
}
