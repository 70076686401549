// Import necessary libraries
import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import 'tailwindcss/tailwind.css';

// TermsAndConditions component
const TermsAndConditions = () => {
    // Animation spring
    const animationProps = useSpring({
        opacity: 1,
        transform: 'translateY(0)',
        from: { opacity: 0, transform: 'translateY(-50px)' },
    });


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <animated.div style={animationProps} className="w-full bg-white p-8 rounded-lg shadow-lg">
                 <animated.h1 className="text-4xl font-bold mb-8 text-center text-indigo-600" style={animationProps}>
                    Terms and Conditions
                </animated.h1>

                <p className="mb-4">Effective Date: <span className="font-semibold">23/11/2023</span></p>


                <p className="mb-6">Welcome to CodeKwiks! These terms and conditions outline the rules and regulations for the use of our web development, Android development, iOS development, cloud services, and database management services.</p>

                <h2 className="text-2xl font-bold mb-4">Acceptance of Terms</h2>
                <p className="mb-4">By accessing or using our services, you agree to be bound by these terms and conditions. If you disagree with any part of these terms, you may not access our services.</p>

                <h2 className="text-2xl font-bold mb-4">Use of Services</h2>
                <p className="mb-4">You must follow any policies made available to you within our services. Do not misuse our services, and only use them as permitted by law and these terms. Your access may be terminated if you fail to comply with these terms.</p>

                <h2 className="text-2xl font-bold mb-4">Intellectual Property</h2>
                <p className="mb-4">The content of our services, including text, graphics, logos, and images, is the property of CodeKwiks and is protected by intellectual property laws. You may not reproduce, modify, or distribute any part of the services without our explicit consent.</p>

                <h2 className="text-2xl font-bold mb-4">User Responsibilities</h2>
                <p className="mb-4">As a user of our services, you are responsible for maintaining the confidentiality of your account information. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>

                <h2 className="text-2xl font-bold mb-4">Changes to Terms</h2>
                <p className="mb-6">We may update these terms from time to time. Any changes will be posted on this page with an updated effective date. Continued use of our services after any such changes constitutes your acceptance of the new terms.</p>

                <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
                <p className="text-gray-600">If you have any questions or concerns about our Terms and Conditions, please contact us at <span className="text-indigo-600">[team@codekwiks.com]</span>.</p>
            </animated.div>
        </div>
    );
}

export default TermsAndConditions;
