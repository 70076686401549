import React from 'react';
import { Link } from 'react-router-dom';

export default function Error() {
  return (
    <>
      <div className="bg-black h-[100vh] w-full flex flex-col text-center items-center text-white justify-center">
        <h1 className="text-[2rem] lg:text-[4rem] font-bold">404 ERROR</h1>
        <h1 className="text-[2rem] lg:text-[4rem] font-bold">Page Not Found</h1>
        <div className="text-[1.5rem] hover:text-[#74e4c0] hover:underline font-medium">
          <Link to="/">Homepage</Link>
        </div>
      </div>
    </>
  );
}
