/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import codekwiks from "../../codekwiks.json";
import react from "../../Codewiks/React.svg";
import wordpress from "../../Codewiks/Woedpress.svg";
import html from "../../Codewiks/HTML CSS.svg";
import shopify from "../../Codewiks/Shopify.svg";
import mong from "../../Codewiks/Mongo.svg";
import php from "../../Codewiks/PHP.svg";
import laravel from "../../Codewiks/Larvel.svg";
import code from "../../Codewiks/Cor.svg";
import express from "../../Codewiks/Express.svg";
import ux from "../../Codewiks/UX.svg";
import { baseURL } from "../../constant";
const url = `${baseURL}/techs`;

export default function Apped() {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);

  const getApps = async () => {
    setLoading(true);
    const response = await fetch(url);
    const users = await response.json();
    const app = users;
    setApps(app);
    if (apps !== []) {
      setLoading(false);
    }
    //console.log(users);
  };
  useEffect(() => {
    setTimeout(() => {
      getApps();
    }, 500)
  }, []);

  console.log(apps)
  return (
    <>
      <div className="hero1 px-6 py-8 lg:p-10 w-full  justify-center items-center">
        <div className="flex items-center justify-center">
          {" "}
          <button className=" cursor-default text-[#74dcbb]  bg-gray-700 items-center justify-center text-center rounded-sm m-3 font-semibold py-2 px-5 text-[1.1rem]">
            Technologies
          </button>
        </div>
        <div className="text-white font-medium pb-10 text-center text-[1.6rem] md:text-[2.5rem] lg:text-[3rem]">
          <h1>
            What Technologies we use
            <span className="text-[#74e4c0]">&#x3F; </span>
          </h1>
        </div>
        <div className="grid grid-cols-2 py-5 justify-center items-center gap-10 md:grid-cols-3 lg:grid-cols-5 lg:gap-12">
          {
            loading ?
              <>
                <h3 className="text-white text-[2rem] text-center flex items-center justify-center">Loading...</h3>
              </>
              :
              <>
                {apps.map((app) => {
                  const { id, img, title } = app;
                  return (
                    <>
                      <div
                        key={id}
                        className="container1 flex py-8 lg:py-16 flex-col text-center items-center justify-center cursor-auto duration-300 hover:scale-110 hover:-translate-x-2 hover:-translate-y-2"
                      >
                        <div className="flex justify-center items-center">
                          <img
                            className="w-auto h-auto px-6"
                            src={img}
                            alt="discover"
                          />
                        </div>
                        <div className="">
                          <h1 className="text-white text-center font-semibold text-auto">
                            {title}
                          </h1>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
          }
        </div>
      </div>
    </>
  );
}
