/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import codekwiks from "../../codekwiks.json";
import img1 from "../../Codewiks/Group 378.svg";
import img2 from "../../Codewiks/image 2.svg";
import img3 from "../../Codewiks/image 3.svg";
import img4 from "../../Codewiks/image 4.svg";
import left from "../../Codewiks/Group 390.svg";
import right from "../../Codewiks/Group 372.png";
import { baseURL } from "../../constant";

export default function Website2({ projectDetail }) {
  // const [projectDetail, setprojectDetail] = useState()
  // const urlParams = new URLSearchParams(window.location.search);
  // const id = urlParams.get('id');
  // const url = `${baseURL}/projects/${id}`;

  let [images, setImage] = useState(projectDetail?.subImages)
  const [currentIndex, setCurrentIndex] = useState(0);

  // const getImages = async () => {
  //   const response = await fetch(url);
  //   const project = await response.json();
  //   setImage(project.subImages)
  //   setprojectDetail(project)
  //   console.log(projectDetail)
  //   //console.log(users);
  // };
  useEffect(() => {
    setImage(projectDetail?.subImages)
  }, [projectDetail]);

  let btnleft = document.querySelector(".btnleft");
  let btnright = document.querySelector(".btnright");
  const handleRight = () => {
    btnleft.classList.remove("pointer-events-none");
    btnleft.classList.remove("opacity-50");
    let isFirstIndex = currentIndex === images.length - 1;
    const newIndex = isFirstIndex ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    if (newIndex === images.length - 1) {
      btnright.classList.add("pointer-events-none");
      btnright.classList.add("opacity-50");
    }
  };
  const handleLeft = () => {
    btnright.classList.remove("pointer-events-none");
    btnright.classList.remove("opacity-50");
    let isFirstIndex = currentIndex === 0;
    const newIndex = isFirstIndex ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    if (newIndex === 0) {
      btnleft.classList.add("pointer-events-none");
      btnleft.classList.add("opacity-50");
    }
  };

  return (
    <div>
      <div className="bg-transparent w-full flex flex-col p-5 lg:p-5 justify-center items-center">
        <div className="flex justify-center items-center">
          {" "}
          <button className="  cursor-default text-[#74dcbb] bg-gray-700 text-center rounded w-[10rem]  font-semibold py-2 px-5 text-[1.1rem]">
            Website
          </button>
        </div>
        <div className="text-white font-medium mb-6 md:mb-20 text-center mt-2 text-[1.6rem] md:text-[2.3rem] lg:text-[3rem]">
          <h1>{projectDetail?.title}</h1>
        </div>
        <div className="mt-1 items-center justify-center flex duration-700">
          <img
            className="image w-full h-[10rem] md:h-[25rem] md:w-[40rem] lg:h-[35rem] lg:w-[70rem]  duration-150 pointer-events-none rounded-lg"
            src={images && images[currentIndex] ? images[currentIndex] : ""}
            alt="lo0ading"
          />
        </div>
        <div className="flex py-5 gap-5 lg:gap-10 items-center justify-center mt-5">
          <div
            onClick={handleLeft}
            className="btnleft pointer-events-none opacity-50 cursor-pointer"
          >
            <img className="w-6 md:w-auto" src={left} alt="loading" />
          </div>
          <div onClick={handleRight} className="btnright cursor-pointer">
            <img className="w-6 md:w-auto" src={right} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
