import React from 'react';
import MainData from "./MainData";

export default function Trending() {
    return (
      <>
        <div className="flex flex-col px-10 md:flex-row items-center justify-center bg-black lg:px-32 lg:justify-between pt-5">
          <MainData/>
        </div>
      </>
    );
}
