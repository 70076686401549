import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import border from "../../Codewiks/Group 24.png";
import main from "../../Codewiks/Group 23.png";
import location from "../../Codewiks/location.png";
import call from "../../Codewiks/call.png";
import sms from "../../Codewiks/sms.png";
import { baseURL } from "../../constant";
export default function Contact() {
  const [users, setUsers] = useState({ firstName: "", email: "", message: "" });
  const url = `${baseURL}/users`;
  const pattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let namerror = document.querySelector(".namerror");
  let emailerror = document.querySelector(".emailerror");
  let messagerror = document.querySelector(".messagerror");
  let alertmessage = document.querySelector(".alert");
  let image = document.querySelector('.border1');
  const handleImage = () => {
    image.src = main;
  }
  const handleImage1 = () => {
    image.src = border;
  };
  let validations = () => {
    if (users.firstName === "") {
      namerror.innerHTML = "please enter your name";
    } else {
      namerror.innerHTML = "";
    }
    if (users.email === "") {
      emailerror.classList.add("text-red-500");
      emailerror.innerHTML = "please enter your email";
    } else if (pattern.test(users.email)) {
      emailerror.classList.add("text-green-700");
      emailerror.innerHTML = "";
    } else {
      emailerror.classList.add("text-red-500");
      emailerror.innerHTML = "Sorry! Invalid email.Please try again.";
    }
    if (users.message === "") {
      messagerror.innerHTML = "please enter your message";
    } else {
      messagerror.innerHTML = "";
    }
  };
  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const handleChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setUsers({ ...users, [name]: value });
    console.log(users);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleImage();
    validations();
    if (users.firstName !== "" && users.email !== "" && users.message !== "") {
      Axios.post(url, users)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
    if (users.firstName !== "" && users.email !== "" && users.message !== "") {
      alertmessage.innerHTML = "Your Response have been Submitted";
    }
    setUsers({ firstName: "", email: "", message: "" });
    alertmessage.innerHTML = "";
  };
  return (
    <>
      <div className="hero flex flex-col p-10 lg:px-32 lg:py-16 justify-center items-center">
        <Link to="/contact">
          <div
            onClick={handleClick}
            className="flex items-center justify-center"
          >
            <button className="  cursor-default text-[#74dcbb] bg-gray-700 text-center rounded-sm font-semibold py-2 px-5 text-[1.1rem]">
              Contact Us
            </button>
          </div>
        </Link>
        <div className="text-white font-medium mb-10 text-center mt-5 text-[1.9rem] md:text-[2rem] lg:text-[3rem]">
          <h1>
            Get in touch with us<span className="text-[#74e4c0]"> ! </span>
          </h1>
        </div>

        <div className="mt-10 lg:my-10  flex flex-col md:flex-row lg:gap-10 justify-center items-center lg:basis-1/2 w-full">
          <div className="z-50 relative">
            <div className="container4 p-[3rem] flex-col  md:pt-[5rem] md:pb-[3rem] md:px-[4rem] lg:px-[2rem]">
              <form
                onSubmit={handleSubmit}
                className="flex-col form"
                onMouseEnter={handleImage}
                onMouseLeave={handleImage1}
              >
                <div className="text-white font-medium text-left text-[1.1rem] lg:text-[1.6rem] mb-5">
                  Fill this form and get a call from our team.
                </div>
                <div className="flex-col">
                  <div className="text-white font-medium text-[1.2rem] md:text-[2rem] lg:text-[1.5rem]">
                    <label htmlFor="name">Name</label>
                  </div>
                  <div className="border-2 border-gray-500 bg-transparent outline-0 mt-2">
                    <input
                      className="name bg-transparent px-3 py-[0.6rem] outline-none text-white font-normal w-full text-[1rem] capitalize"
                      type="text"
                      name="firstName"
                      value={users.firstName}
                      onChange={handleChange}
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="namerror text-red-500 font-semibold"></div>
                </div>
                <div className="flex-col">
                  <div className="text-white font-medium text-[1.2rem] md:text-[2rem] lg:text-[1.5rem] mt-6">
                    <label htmlFor="name">Email</label>
                  </div>
                  <div className="border-2  border-gray-500 bg-transparent outline-0 mt-2">
                    <input
                      className="email bg-transparent px-3 py-[0.6rem] outline-none text-white font-normal w-full text-[1rem]"
                      type="email"
                      name="email"
                      value={users.email}
                      onChange={handleChange}
                      placeholder="Enter your Mail ID"
                    />
                  </div>
                  <div className="emailerror font-semibold"></div>
                </div>
                <div className="flex-col">
                  <div className="text-white font-medium text-[1.2rem] md:text-[2rem] lg:text-[1.5rem] mt-6">
                    <label htmlFor="name">Message</label>
                  </div>
                  <div className="border-2  border-gray-500  bg-transparent outline-0 mt-2">
                    <textarea
                      className="bg-transparent message px-3 py-3 outline-none text-white font-normal w-full text-[1rem]"
                      type="text"
                      name="message"
                      value={users.message}
                      onChange={handleChange}
                      placeholder="Type your Message here !"
                    />
                  </div>
                  <div className="messagerror text-red-500 font-semibold"></div>
                </div>
                <div className="flex justify-start items-center">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submit hover:cursor-pointer bg-[#74e4c0] hover:bg-[#8332ca] hover:text-white text-black text-center rounded-sm w-[10rem] md:w-[12rem] mt-9 font-semibold py-2 px-5 text-[1.2rem]"
                  >
                    Send &#8594;
                  </button>
                </div>
                <div className="font-bold text-[1.5rem] text-white  mt-10 alert"></div>
              </form>
              <div
                className="hidden lg:flex absolute -right-10 -bottom-8 z-0"
                style={{ zIndex: -1 }}
              >
                <img
                  src={border}
                  alt=""
                  className="border1 pointer-events-none"
                />
              </div>
            </div>
          </div>

          <div className="justify-center items-center flex">
            <div className=" text-white mt-4 lg:mt-40  text-left lg:px-16">
              <div className="flex flex-col mt-2 md:flex-row gap-10 lg:gap-16 justify-center items-center">
                <div className="flex-col">
                  <div className="flex md:px-20">
                    <div className="container3 w-12 h-12 p-2 md:p-3 mr-6  cursor-pointer">
                      <img src={location} alt="" />
                    </div>
                    <div className="text-slate-400 font-medium text-[1.2rem] md:text-[1.7rem] mt-1">
                      <h4>Address</h4>
                    </div>
                  </div>
                  <div className=" mt-5 md:ml-20 text-[1.2rem] md:text-[1.3rem] font-bold text-white">
                    <p>606 Levering Ave, Los Angeles, California, 90024</p>
                  </div>
                  <div className=" md:ml-20 mt-6 text-gray-500 mb-20 md:mb-10">
                    <hr />
                  </div>
                  <div className="flex md:px-20">
                    <div className="container3 w-12 h-12 p-2 md:p-3 mr-6 cursor-pointer">
                      <img src={call} alt="" />
                    </div>
                    <div className="text-slate-400 font-medium text-[1.2rem] md:text-[1.7rem] mt-1">
                      <h4>Contact</h4>
                    </div>
                  </div>
                  <div className=" mt-5 md:ml-20 text-[1.2rem] md:text-[1.3rem] font-bold text-white">
                    <p> +1 818 458 0086</p>
                  </div>
                  <div className=" md:ml-20 mt-6 text-gray-500 mb-20 md:mb-10">
                    <hr />
                  </div>
                  <div className="flex md:px-20">
                    <div className="container3 w-12 h-12 p-2 md:p-3 mr-6  cursor-pointer">
                      <img src={sms} alt="" />
                    </div>
                    <div className="text-slate-400  font-medium text-[1.2rem] md:text-[1.5rem] mt-1">
                      <div>
                        <h4>Mail Address</h4>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-5 md:ml-20 text-[1.2rem] md:text-[1.3rem] font-bold text-white">
                    <p>team@codekwiks.com</p>
                  </div>
                  <div className=" md:ml-20 mt-6 text-gray-500">
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
