/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../../App.css";
import { Link, useNavigate } from "react-router-dom";
import border from "../../Codewiks/Group 371.png";
import codekwiks from "../../codekwiks.json";
import img1 from "../../Codewiks/Group 378.svg";
import img2 from "../../Codewiks/image 3.svg";
import react from "../../Codewiks/React.svg";
import wordpress from "../../Codewiks/Woedpress.svg";
import shopify from "../../Codewiks/Shopify.svg";
import php from "../../Codewiks/PHP.svg";
import laravel from "../../Codewiks/Larvel.svg";
import { baseURL } from "../../constant";
import { useQuery } from "react-query";
const url = `${baseURL}/projects`;

export default function Website({ update, setupdate }) {
  const navigate = useNavigate()

  const redirectTo = (endpoint) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setupdate(!update)
    return navigate(endpoint)
  }
  const { isLoading, error, data } = useQuery('projects', async () => {
    const response = await fetch(url)
    const result = await response.json();
    return result
  })
  const [projects, setProjects] = useState(data);
  const [Disable, setDisable] = useState(false);
  let [projectSlice, setProjectSlice] = useState(2);

  useEffect(() => {
    setProjects(data)
  }, [isLoading])
  // const getProjects = async () => {
  //   setLoading(true);
  //   const response = await fetch(url);
  //   const users = await response.json();
  //   let project1 = users[Math.ceil(Math.random() * (users.length - 1))]
  //   console.log(project1)
  //   let project2 = users[Math.floor(Math.random() * (users.length))]
  //   console.log(project2)
  //   let projectss = [project1, project2];
  //   console.log(projectss);
  //   setProjects(projectss);
  //   if (projects !== []) {
  //     setLoading(false)
  //   }
  //   //console.log(users);
  // };
  // useEffect(() => {
  //   getProjects();
  // }, []);
  const handleClick = () => {
    setProjectSlice(projectSlice + 4)
    if (projectSlice >= projects.length) {
      setDisable(true)
    } else {
      setDisable(false)

    }
  };
  useEffect(() => {
    let pages = document.querySelectorAll(".item");
    pages.forEach((page) => {
      page.addEventListener("click", () => {
        document.querySelector(".item1").classList.remove("item1");
        page.classList.add("item1");
      });
    });
  });

  return (
    <>
      <div className="hero flex flex-col items-center justify-center px-10 lg:py-10 w-full">
        <div className="flex items-center justify-center">
          {" "}
          <button className=" cursor-default text-[#74dcbb] items-center justify-center mt-12 bg-gray-700 text-center rounded-sm w-[10rem] mb-4 font-semibold py-2 px-5 text-[1.2rem]">
            Projects
          </button>
        </div>
        <div className="text-white font-medium text-center text-[1.8rem] md:text-[2.4rem] lg:text-[3rem]">
          <h1>Related Projects</h1>
        </div>
        <div className="flex flex-col md:grid p-10 md:grid-cols-2 lg:pt-2 md:gap-20 lg:gap-10 items-center  justify-center  w-full">
          {isLoading ? (
            <>
              <h3 className="text-white text-[2rem] text-center flex items-center justify-center">
                Loading...
              </h3>
            </>
          ) : (
            <>
              {projects?.slice(0, projectSlice).map((project) => {
                const { _id, img, title, description, btn, images } = project;
                return (
                  <div key={_id} className="">
                    <div className="flex-col">
                      <div className="bg-transparent lg:p-[2rem] w-auto h-auto mb-12 md:mb-0 flex-col">
                        <div
                          className="flex items-center justify-center md:justify-start"

                        >
                          {" "}
                          <button className=" cursor-default text-[#74dcbb] bg-gray-700 text-center rounded-sm w-[12rem] lg:w-[10rem] font-semibold mb-8 lg:mb-4  lg:mt-12 py-2 text-[1.1rem]">
                            {btn}
                          </button>
                        </div>

                        <div className="relative z-50 lg:pb-8">
                          <div className="">
                            <img
                              className="lg:mt-4 h-auto md:mb-12 lg:mb-0 md:ml-0 rounded-lg"
                              src={img}
                              alt=""
                            />
                          </div>
                          <div
                            className=" absolute w-auto bottom-2 -right-4"
                            style={{ zIndex: -1 }}
                          >
                            <img
                              className="pointer-events-none hidden lg:flex"
                              src={border}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" text-white -mt-12  text-left lg:ml-8 p-2">
                      <div className="text-[2rem] sm:text-[2rem] lg:text-[3rem] font-semibold mb-1">
                        {title}
                      </div>
                      <div className="text-[1.15rem] lg:[1.3rem] text-left w-auto text-slate-300 font-normal lg:text-left mb-4">
                        {description}
                      </div>
                      <div className="grid grid-cols-2 md:grid-cols-3 lg:my-2  lg:grid-cols-5 justify-center items-center md:w-[20rem] lg:w-[28rem]">
                        {images.map((image) => {
                          const { id, img, title } = image;
                          return (
                            <div
                              key={id}
                              className="flex flex-col mb-2 gap-2 justify-center items-center"
                            >
                              <div className="w-[3rem] mb-1">
                                <img className="" src={img} alt="" />
                              </div>
                              <div className="text-center">{title}</div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-col lg:flex-row gap-2 mb-6 lg:gap-5 justify-center lg:justify-start items-center">
                        <button
                          onClick={() => redirectTo(`/website?id=${_id}`)}
                          className="bg-transparent text-center border-2 hover:border-2 hover:text-blue-500 hover:border-blue-500 w-[12rem] py-2 px-5 font-semibold md:m-5 text-[1.2rem]"
                        >
                          Visit Site &#8594;
                        </button>
                        <button
                          onClick={() => redirectTo(`/website?id=${_id}`)}
                          className="bg-[#74e4c0] hover:bg-[#8332ca] hover:text-white text-black text-center rounded-sm w-[12rem] font-semibold py-2 px-5 text-[1.2rem]"
                        >
                          Know More &#8594;
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="items-center justify-center flex">

          <button
            onClick={handleClick}
            className={Disable ? "hidden" : "bg-transparent text-center mb-20 text-white border-2 hover:border-2 hover:text-blue-500 hover:border-blue-500 w-[12rem] py-2 px-5 font-semibold text-[1.2rem]"}
            disabled={Disable}
          >
            Load More &#8594;
          </button>
        </div>
      </div>
    </>
  );
}
