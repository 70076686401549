import React from "react";
import { Link, useNavigate } from "react-router-dom";
import wordpress from "../../Codewiks/Woedpress.svg";
import html from "../../Codewiks/HTML CSS.svg";
import shopify from "../../Codewiks/Shopify.svg";
import react from "../../Codewiks/React.svg";
import php from "../../Codewiks/PHP.svg";
import ImagesData from "./ImagesData";

export default function Website1({ projectDetail }) {
  console.log(projectDetail)

  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }


  const images = [
    { id: 1, img: wordpress, title: "Wordpress" },
    { id: 2, img: react, title: "React JS" },
    { id: 3, img: shopify, title: "Shopify" },
    { id: 4, img: html, title: "HTML" },
    { id: 5, img: php, title: "PHP" },
  ];
  return (
    <>
      <div className="hero2 flex-col p-10 md:p-12 lg:px-12 lg:pt-8  justify-center items-center">
        <div className="-ml-6 text-white text-medium text-[1.3rem]">
          <span onClick={handleClick} className="hover:text-[#74e4c0]">
            <Link to="/">Home</Link> &#8594;{" "}
          </span>
          <span onClick={handleClick} className="hover:text-[#74e4c0]">
            {" "}
            <Link to="/projects">Projects</Link> &#8594;{" "}
          </span>
          <span className="text-[#74e4c0]">{projectDetail?.title}</span>
        </div>
        <ImagesData projectDetail={projectDetail} />
        <div className=" text-white border-y border-gray-500  text-left px-5 lg:px-2 py-6 lg:py-8">
          <div className="text-[2rem] md:text-[2.5rem] -ml-4 lg:ml-0 font-semibold mb-2">
            Project Brief
          </div>
          <div className="text-[1rem] lg:text-[1.5rem] text-white opacity-80 font-normal text-left mb-2 lg:w-[70rem]">
            {/* We specialize in building beautiful, professional, and easy-to-use
            websites.It includes from small business websites to large ecommerce
            websites.We build engaging, responsive, secure,optimized, and
            lightning-fast websites that delight visitors and outshine your
            brand.{" "} */}
            {projectDetail?.description}
          </div>
        </div>
        <div className=" text-white flex flex-col md:flex-row  text-left px-5 lg:px-2">
          <div className="lg:w-1/2 md:border-r border-gray-500">
            <div className="text-[2rem] md:text-[2.5rem] font-semibold mb-2 md:pr-5 lg:pr-0 py-3 ">
              Services Provided
            </div>
            <div className="text-[1.25rem] text-white opacity-80 font-normal text-left mb-2">
              <ul className="grid grid-cols-1 lg:grid-cols-2 list">
                <li>Project Discovery</li>
                <li>Design Prototype</li>
                <li>Frontend Development</li>
                <li>Backend Development</li>
                <li>Software Testing & QA</li>
              </ul>
            </div>
          </div>
          <div className=" text-white  text-left md:px-8 py-3  lg:w-1/2">
            <div className="flex text-[1.3rem] md:text-[2.5rem] font-semibold mb-5">
              Technologies Used
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:my-2  lg:grid-cols-5 justify-center items-center md:w-[20rem] lg:w-[28rem]">
              {images.map((image) => {
                const { id, img, title } = image;
                return (
                  <div
                    key={id}
                    className="flex flex-col mb-2 gap-2 justify-center items-center"
                  >
                    <div className="w-[3rem] mb-1">
                      <img className="" src={img} alt="" />
                    </div>
                    <div className="text-center">{title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-10  md:flex-row md:gap-12 justify-center items-center">
          <Link to="/contact">
            <button

              className="bg-transparent text-center border-2 w-[15rem] hover:border-2 hover:text-blue-500 hover:border-blue-500 text-white py-2 px-5 font-semibold my-3 text-[1.2rem]"
            >
              Contact Us &#8594;
            </button>
          </Link>
          <Link to="/services">
            <button

              className="bg-[#74e4c0] hover:bg-[#8332ca] hover:text-white text-black text-center rounded-sm  w-[15rem] my-3 font-semibold py-2 px-5 text-[1.2rem]"
            >
              Visit Site &#8594;
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
