import React, { useState, useEffect } from "react";
import Loading from "../LoaderPage/Loading";
import Header from "./WebsiteHeader";
import WebsiteBottom from "./WebsiteBottom";
import WebsiteMidbar from "./WebsiteMidbar";
import Contact from "../HomePage/Contact";
import Footer from "../HomePage/Footer";
import { baseURL } from "../../constant";

export default function MainHeader() {

  const [projectDetail, setprojectDetail] = useState()
  let [images, setImage] = useState([])

  const [update, setupdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const url = `${baseURL}/projects/${id}`;

  const getImages = async () => {
    const response = await fetch(url);
    const project = await response.json();
    setImage(project.subImages)
    setprojectDetail(project)
    console.log(projectDetail)
  };

  useEffect(() => {
    getImages()
  }, [update, id,]);

  console.log(projectDetail)



  return (
    <>
      <Header projectDetail={projectDetail} />
      <div>{loading ? <Loading /> : <WebsiteMidbar projectDetail={projectDetail} update={update} />}</div>
      <div>{loading ? <Loading /> : <WebsiteBottom setupdate={setupdate} update={update} />}</div>
      <Contact />
      <Footer />
    </>
  );
}
