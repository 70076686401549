import React, { useEffect } from 'react';
import discover from '../../Codewiks/_Group_.png';
import main from '../../Codewiks/Group 23.png';
import main2 from '../../Codewiks/Group 371.png';
import strategy from '../../Codewiks/Group 36.png';
import design from '../../Codewiks/Group 38.png';
import build from '../../Codewiks/Group 41.png';
import deliver from '../../Codewiks/Group 43.png';
import '../../App.css';

export default function Hero() {
  useEffect(() => {
    let containers = document.querySelectorAll('.container5');
    let images = document.querySelectorAll('#demo');
    containers.forEach((container) => {
      container.addEventListener('mouseover', () => {
        let image = container.querySelector('#demo');
        images.forEach((img) => {
          img.src = main2;
        })
        image.classList.add('demo');
        let mainImg = container.querySelector('.demo');
        mainImg.src = main;
      })
    })
  })

  return (
    <div className="hero flex-col w-full  py-12 md:py-16 justify-center items-center">
      <div className="flex items-center justify-center">
        {" "}
        <button className=" cursor-default text-[#74dcbb] items-center justify-center bg-gray-700 text-center rounded mb-4 font-semibold py-2 px-5 text-[1.2rem]">
          Our Qualities
        </button>
      </div>
      <div className="text-white font-medium mb-10 md:mb-12 text-center text-[1.8rem] md:text-[2.4rem] lg:text-[3rem]">
        <h1>
          What are our Qualities<span className="text-[#74e4c0]">&#x3F; </span>
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row gap-10 lg:gap-24 mb-10 lg:mb-20 justify-center items-center lg:px-10">
        <div className="container5 relative mb-6">
          <div className="container flex-col  w-[15rem] h-84  md:w-[18rem] md:h-96 text-center items-center justify-center py-16 cursor-pointer">
            <div className="justify-center items-center flex">
              <img className="img1 p-5" src={discover} alt="discover" />
            </div>
            <h1 className="text-white text-center mt-3 font-semibold text-[1.3rem] lg:text-[1.5rem]">
              Discover
            </h1>
            <p className=" text-slate-200 text-center px-4 mt-3">
              We shape brands through exploration,applying in-depth research to
              challenge assumptions at every turn.
            </p>
            <div className="absolute -bottom-8 -right-7">
              <img
                id="demo"
                className="image cursor-pointer flex"
                src={main}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container5 relative mb-6">
          <div className="container flex-col  w-[15rem] h-84  md:w-[18rem] md:h-96 text-center items-center justify-center py-16 cursor-pointer">
            <div className="flex items-center justify-center">
              <img className=" img2 p-5" src={strategy} alt="discover" />
            </div>
            <h1 className="text-white mt-3 text-center font-semibold text-[1.3rem] lg:text-[1.5rem]">
              Strategy
            </h1>
            <p className=" text-slate-200 text-center px-4 mt-3">
              We craft research-backed product strategies that help our process
              move through the key executionstages in an efficient manner,
              without the neeed to backtrack.
            </p>
            <div className="absolute -bottom-8 -right-7">
              <img
                id="demo"
                className="image cursor-pointer flex"
                src={main2}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container5 relative mb-6">
          <div className="container flex-col  w-[15rem] h-84  md:w-[18rem] md:h-96 text-center items-center justify-center py-16 cursor-pointer">
            <div className="justify-center items-center flex">
              <img className="img3 p-5" src={design} alt="discover" />
            </div>
            <h1 className="text-white mt-3 text-center font-semibold text-[1.3rem] lg:text-[1.5rem]">
              Design
            </h1>
            <p className=" text-slate-200 text-center px-4 mt-3">
              Our design approach is to simplify. We embrace the joy in creating
              something unique that is easy for end users.
            </p>
            <div className="absolute -bottom-8 -right-7">
              <img
                id="demo"
                className="image cursor-pointer flex"
                src={main2}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-10 lg:gap-24 mb-10 lg:mb-10 justify-center items-center lg:px-10">
        <div className="container5 relative mb-6">
          <div className="container flex-col w-[15rem] h-84  md:w-[18rem] md:h-96  text-center items-center justify-center py-16 cursor-pointer">
            <div className="justify-center items-center flex">
              <img className="img4 p-5" src={build} alt="build" />
            </div>
            <h1 className="text-white text-center mt-3 font-semibold text-[1.3rem] lg:text-[1.5rem]">
              Build
            </h1>
            <p className=" text-slate-200 text-center px-4 mt-3">
              Using modern technologies, we build with efficiency and skill,
              creating flexible and scalable business-driven solutions.{" "}
            </p>
            <div className="absolute -bottom-8 -right-7">
              <img
                id="demo"
                className="image cursor-pointer flex"
                src={main2}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container5 relative mb-6">
          <div className="container flex-col  w-[15rem] h-84  md:w-[18rem] md:h-96  text-center items-center justify-center py-16 cursor-pointer">
            <div className="justify-center items-center flex">
              <img className="img5 p-5" src={deliver} alt="deliver" />
            </div>
            <h1 className="text-white mt-3 text-center font-semibold text-[1.3rem] lg:text-[1.5rem]">
              Deliver
            </h1>
            <p className=" text-slate-200 text-center px-4 mt-3">
              We take an iterative approach to both our work and our practice,
              always looking for ways to improve what we do.
            </p>
            <div className="absolute -bottom-8 -right-7">
              <img
                id="demo"
                className="image cursor-pointer flex"
                src={main2}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
