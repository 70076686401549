import React, { useState, useEffect } from 'react';
import Loading from "../LoaderPage/Loading";
import Header from "./Header";
import Technologies from "./Apps";
import Clients from "./Clients";
import Contact from "./Contact";
import MainMidbar from "./Midbar";
import MainServices from "./Service";
import Testimonials from "./Testemonials";
import Data from "./Data";
import Footer from "./Footer";

export default function MainHeader() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  return (
    <>
      <Header />
      <MainMidbar />
      <div>{loading ? <Loading /> : <Technologies />}</div>
      <div>{loading ? <Loading /> : <MainServices />}</div>
      <div>{loading ? <Loading /> : <Clients />}</div>
      <div>{loading ? <Loading /> : <Testimonials />}</div>
      <div>{loading ? <Loading /> : <Data />}</div>
      <Contact />
      <Footer />
    </>
  );
}
