/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import codekwiks from "../../codekwiks.json";
import border from "../../Codewiks/Group 141.png";
import web from "../../Codewiks/Group 262.svg";
import mobile from "../../Codewiks/code.svg";
import ux from "../../Codewiks/Group 259.svg";
import { baseURL } from "../../constant";
const url = `${baseURL}/services`;

export default function Service() {
  // const service = codekwiks.Services;
  const [service, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const getServices = async () => {
    setLoading(true);
    const response = await fetch(url);
    const users = await response.json();
    const services = users;
    setServices(services);
    if (service !== []) {
      setLoading(false);
    }
    //console.log(users);
  };
  useEffect(() => {
    setTimeout(() => {
      getServices();
    }, 500)

  }, []);
  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="hero2 flex flex-col p-10 lg:pt-12 lg:px-20 w-full  justify-center items-center">
        <Link to="/services">
          <div
            onClick={handleClick}
            className="flex items-center justify-center"
          >
            {" "}
            <button className="  cursor-default text-[#74dcbb] bg-gray-800 text-center rounded-sm font-semibold py-2 px-5 text-[1.1rem]">
              Services
            </button>
          </div>
        </Link>
        <div className="text-white font-medium pb-10 text-center text-[1.8rem] md:text-[2.6rem] lg:text-[3rem]">
          <h1>
            What services we provide
            <span className="text-[#74e4c0]">&#x3F; </span>
          </h1>
        </div>
        <div className="mt-10 flex-col w-full">
          {
            loading ?
              <>
                <h3 className="text-white text-[2rem] text-center flex items-center justify-center">Loading...</h3>
              </>
              :
              <>
                {service.map((service) => {
                  const { id, img, name, description } = service;
                  return (
                    <>
                      <div className="pb-5 flex flex-col lg:flex-row w-full items-center justify-center">
                        <div
                          key={id}
                          className="container2 pointer-events-none w-[17rem] p-[1.5rem] md:w-[22rem] lg:h-[22rem] md:p-[2rem] z-10 "
                        >
                          <img className="w-full" src={img} alt="" />
                        </div>
                        <div className=" text-white relative ml-5 text-left px-5 lg:mt-4 lg:h-[25rem] ">
                          <div className="text-[1.3rem] mt-10 lg:mt-20 sm:text-[1.3rem] md:text-[1.9rem] font-semibold mb-5">
                            {name}
                          </div>
                          <div className="text-white opacity-80 font-normal text-justify lg:text-left  mb-4 w-[18rem] md:w-[35rem] xl:w-[50rem]">
                            {description}
                          </div>
                          <div className="flex flex-col mb-2 md:flex-row gap-2 md:gap-8 mt-10 justify-center lg:justify-start items-center">
                            <Link to="/contact">
                              <button
                                onClick={handleClick}
                                className="bg-transparent  hover:border-2 hover:text-blue-500 hover:border-blue-500 text-center border-2 w-[15rem] py-2 px-5 font-semibold text-[1.2rem]"
                              >
                                Contact Us &#8594;
                              </button>
                            </Link>
                            <Link to="/services">
                              <button
                                onClick={handleClick}
                                className="bg-[#74e4c0] hover:bg-[#8332ca] hover:text-white  text-black text-center rounded-sm w-[15rem] font-semibold py-2 px-5 text-[1.2rem]"
                              >
                                Know More &#8594;
                              </button>
                            </Link>
                          </div>
                          <div className=" absolute bottom-2 -left-6 hidden pointer-events-none lg:flex h-[22rem] mb-20 sm:mb-20 md:mb-6">
                            <img src={border} alt="" />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
          }
        </div>
      </div>
    </>
  );
}
