// Import necessary libraries
import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import 'tailwindcss/tailwind.css';

// PrivacyPolicy component
const PrivacyPolicy = () => {
    // Animation spring
    const animationProps = useSpring({
        opacity: 1,
        transform: 'translateY(0)',
        from: { opacity: 0, transform: 'translateY(-50px)' },
    });

    // Set scroll position to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <animated.div style={animationProps} className="w-full bg-white p-8 rounded-lg shadow-lg">
            <h1 className="text-4xl font-bold mb-8 text-center text-indigo-600">Privacy Policy</h1>

            <p className="mb-4">Effective Date: <span className="font-semibold">23/11/2023</span></p>


                <p className="mb-6">Thank you for choosing CodeKwiks for your web development, Android development, iOS development, cloud services, and database management needs. Your privacy is important to us. This Privacy Policy is meant to help you understand how we collect, use, and safeguard your personal information.</p>

                <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
                <p className="mb-4">We may collect personal information when you use our services or interact with our website. This information may include, but is not limited to:</p>
                <ul className="list-disc ml-6 mb-6">
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Company name</li>
                    <li>Other relevant information for providing our services</li>
                </ul>

                <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
                <p className="mb-4">We use the collected information for various purposes, including:</p>
                <ul className="list-disc ml-6 mb-6">
                    <li>Providing and maintaining our services</li>
                    <li>Communicating with you</li>
                    <li>Improving our services</li>
                    <li>Customizing your experience</li>
                    <li>Analytics and research</li>
                </ul>

                <h2 className="text-2xl font-bold mb-4">Security</h2>
                <p className="mb-4">We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>

                <h2 className="text-2xl font-bold mb-4">Changes to This Privacy Policy</h2>
                <p className="mb-6">We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

                <p className="text-gray-600">If you have any questions or concerns about our Privacy Policy, please contact us at <span className="text-indigo-600">[team@codekwiks.com]</span>.</p>
            </animated.div>
        </div>
    );
}

export default PrivacyPolicy;
