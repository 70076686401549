import React, { useState, useEffect } from "react";
import Loading from "../LoaderPage/Loading";
import Header from "./ErrorHeader";

export default function MainHeader() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return <div>{loading ? <Loading /> : <Header />}</div>;
}
