/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import per11 from "../../Codewiks/IM1.svg";
import per21 from "../../Codewiks/IM2.svg";
import per31 from "../../Codewiks/IM3.svg";
import codekwiks from "../../codekwiks.json";
import left from "../../Codewiks/Group 390.svg";
import right from "../../Codewiks/Group 372.png";
import { baseURL } from "../../constant";
const url = `${baseURL}/testimonials`;
export default function Testimonials() {
  // const images = codekwiks.Testimonials;
  let [mainItem, setMainItem] = useState([])
  let [items, setItems] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleRightClick = async () => {
    const response = await fetch(url);
    const users = await response.json();
    const image = users;
    image.forEach((image) => {
      image.bool = false;
    });
    let isFirstIndex = currentIndex === image.length - 1;
    const newIndex = isFirstIndex ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    image[currentIndex].bool = true;
    if (newIndex === image.length - 1) {
    }
    let mainItems = image.filter((image1) => image1.bool === true);
    let itemss = image.filter((image1) => image1.bool === false);
    setMainItem(mainItems);
    setItems(itemss);
  };

  const handleLeftClick = async () => {
    const response = await fetch(url);
    const users = await response.json();
    const image = users;
    image.forEach((image) => {
      image.bool = false;
    });
    let isFirstIndex = currentIndex === 0;
    const newIndex = isFirstIndex ? image.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    image[currentIndex].bool = true;
    let mainItems = image.filter((image1) => image1.bool === true);
    let itemss = image.filter((image1) => image1.bool === false);
    setMainItem(mainItems);
    setItems(itemss);
  };
  useEffect(() => {
    handleRightClick();
  }, [])
  useEffect(() => {
    handleLeftClick();
  }, [])

  const getImages = async () => {
    const response = await fetch(url);
    const users = await response.json();
    const image = users;
    let mainItems = image.filter((image1) => image1.bool === true);
    let itemss = image.filter((image1) => image1.bool === false);
    setMainItem(mainItems);
    setItems(itemss);
    //console.log(users);
  };

  useEffect(() => {
    getImages();
  }, []);

  console.log(currentIndex)

  return (
    <div>
      {mainItem.map((item) => {
        const { id, name, img, position, description } = item;
        return (
          <>
            <div className="bg-black w-full hero flex-col p-10 md:p-10 lg:p-20 justify-center items-center">
              <div className="flex items-center justify-center">
                {" "}
                <button className="  cursor-default text-[#74dcbb] bg-gray-700 text-center rounded w-[10rem]  font-semibold py-2 px-5 text-[1.1rem]">
                  Testimonials
                </button>
              </div>
              <div className="text-white font-medium mb-6 md:mb-10 text-center mt-4 text-[1.6rem] md:text-[2.3rem] lg:text-[3rem]">
                <h1>
                  What people are saying
                  <span className="text-[#74e4c0]">&#x3F;</span>
                </h1>
              </div>
              <div key={id} className="flex justify-center items-center py-8">
                <div className="-mr-8">
                  <img src={items[0].img} alt="" className="img opacity-20" />
                </div>
                <div className="z-10 -mt-3">
                  <img src={img} alt="" className="img" />
                </div>
                <div className="-ml-8">
                  <img src={items[1].img} alt="" className="img opacity-20" />
                </div>
              </div>
              <div className="text-center text-[1.6rem] md:text-[1.8rem] text-white">
                <h1>{name}</h1>
              </div>
              <div className="text-center text-[1.6rem] md:text-[1.8rem] text-[#74e4c0]">
                <h1>{position}</h1>
              </div>
              <div className="text-[1.2rem] lg:text-[1.4rem] text-slate-500 font-normal text-center mt-12">
                {description}
              </div>
              <div className="flex items-center gap-10 mt-8 justify-center cursor-pointer scale-100 md:scale-125 lg:scale-110 ">
                <div
                  onClick={handleLeftClick}
                  className="btnleft"
                >
                  <img className="" src={left} alt="" />
                </div>
                <div onClick={handleRightClick} className="btnright">
                  <img className="" src={right} alt="" />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}
